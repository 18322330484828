import React from 'react'
import { Modal } from 'antd'

interface CommonModalProps {
    title?: React.ReactNode
    isVisible: boolean
    content: React.ReactNode
    onOk?: () => void
    onCancel?: () => void
    okText?: string
    cancelText?: string
    width?: number
    style: React.CSSProperties
    cancelButtonProps?: any
    okButtonProps?: any
    footer?: any
    classNames?: any
}

const CommonModal: React.FC<CommonModalProps> = ({ title, isVisible, content, onOk, onCancel,okText, cancelText, width, style, cancelButtonProps, okButtonProps, footer, classNames}) => {
    return (
        <Modal className='whitespace-pre-wrap'
               closable={false} // x 표시 제거
               title={title}
               style={style}
               open={isVisible}
               onOk={onOk}
               onCancel={onCancel}
               okText={okText}
               cancelText={cancelText}
               width={width}
               cancelButtonProps={cancelButtonProps}
               okButtonProps={okButtonProps}
               footer={footer}
               classNames={classNames}
        >   {content}
        </Modal>
    );
};

export default CommonModal;

