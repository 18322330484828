import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import {Controller, useForm} from 'react-hook-form'
import { clientNotificationController } from '../../dao/notificationController'
import withSession from '../../lib/session'
import styles from '../../styles/m/login.module.css'
import '../../support/bridge'
import { login } from '../api/login'
import httpClient from '../../dao/http-clients/httpClient'
import {Typography, Input} from "antd";
import CommonModal from "../../components/common/CommonModal";
import httpServer from "../../dao/http-clients/httpServer";

declare global {
    // ⚠️ noticeBefore that "Window" is capitalized here
    interface Window {
        settingSaveInfoForIos: any;
    }
}

const LoginPage = () => {
    const router = useRouter()
    const [error, setError] = useState(false)
    const [inspection, setInspection] = useState(false)
    const [cookies, setCookies] = useCookies(['isSave', 'saveId', 'savePw'])
    const [isSave, setIsSave] = useState(cookies.isSave ? cookies.isSave : 'false')
    const [inputText, setInputText] = useState()
    const [isChangeInputText, setIsChangeInputText] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { Title } = Typography

    const handleGoToJoin = async () => {
        await router.push('/join')
    }
    const handleOk = () => {
        setIsModalOpen(false);
        setIsChangeInputText(!isChangeInputText)
    };
    const onChangeInputText = (e) => {
        setInputText(e.target.value)
    }
    useEffect(() => {
        if (error) {
            setIsChangeInputText(true)
        }
    }, [inputText])

    const { handleSubmit, setValue, control, formState: { errors } } = useForm({ defaultValues: { userId: '', userPw: '' } })

    useEffect(() => {
        if (window?.webkit) {
            window?.webkit?.messageHandlers?.getToken?.postMessage('')
            window?.webkit?.messageHandlers?.getSaveInfo?.postMessage('')
        } else if (window?.android) {
            window?.android?.getToken('')
            window?.android?.getSaveInfo()
        }
    }, [])

    const [saveCheck, setSaveCheck] = useState(false)
    useEffect(() => {
        if (isSave == 'true') {
            setSaveCheck(true)
            setValue('userId', cookies.saveId)
            setValue('userPw', cookies.savePw)
        }
    }, [])
    // mobile 아이디 저장시 실행됨.
    useEffect(() => {
        // AOS 용
        const infoCallback = async (event) => {
            if (event.detail.isSave === 'true') {
                setSaveCheck(true)
                setValue('userId', event.detail.saveId)
                setValue('userPw', event.detail.savePw)
            }
        }
        window.addEventListener('settingSaveInfo', infoCallback)

        // IOS 용
        window.settingSaveInfoForIos =(id:string, pw:string)=>{
            setSaveCheck(true)
            setValue('userId',id)
            setValue('userPw',pw)
        }
    },[])

    const onSubmit = async (submitData) => {
        const { userId, userPw } = submitData
        const check = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/
        if (check.test(userId)) {
            setError(true)
            setInspection(false)
            return
        }

        try {
            const appToken = window?.bridge?.info?.token
            const result = await login(userId, userPw, appToken || '')
            httpClient.setSecurityData(result.data.access_token)

            if (appToken) {
                await clientNotificationController.register({
                    token: appToken,
                    loginId: userId,
                })
            }

            if (window?.webkit) {
                window.webkit.messageHandlers?.saveLogin?.postMessage(result.data)
                if (saveCheck) {
                    const saveInfo = {
                        saveId: userId,
                        savePw: userPw,
                    }
                    window.webkit.messageHandlers?.saveInfo?.postMessage(saveInfo)
                } else {
                    window.webkit.messageHandlers?.saveDelete?.postMessage('')
                }
            } else if (window?.android) {
                window.android.saveLogin(result.data)
                if (saveCheck) {
                    window.android.saveInfo(userId, userPw)
                } else {
                    window.android.saveDelete()
                }
            } else {
                if (saveCheck) {
                    setCookies('isSave', 'true')
                    setCookies('saveId', userId)
                    setCookies('savePw', userPw)
                } else {
                    setCookies('isSave', 'false')
                    setCookies('saveId', '')
                    setCookies('savePw', '')
                }
            }

            const target = result.data.userData.isCredentialsNonExpired ? 'home' : 'setting/password'

            if (/Mobi|Android/i.test(navigator.userAgent)) {
                await router.push(`/m/${target}`)
            } else {
                await router.push(`/web/${target}`)
            }
        } catch (e: any) {
            if (e.toString().indexOf(503) > -1) {
                setInspection(true)
                setError(false)
            } else {
                setInspection(false)
                setError(true)
                setIsChangeInputText(false)
            }
        }
    }

    return (
        <div className='h-screen'>
            <div className='flex justify-center bg-prime-blue'>
                <img
                    src='/ctrl/api/v2/logo'
                    alt='logo'
                    className='max-w-[140px] max-h-[100px] object-contain my-3'
                />
            </div>
        <form className='w-full flex justify-center items-center' onSubmit={handleSubmit(onSubmit)}>
            <div>
                <Title className='text-2xl font-bold text-center mt-24 mb-8'>
                     MQV 스마트오피스 로그인
                </Title>
                <div className='flex flex-col justify-between items-center'>

                    <div>
                    <div className='text-xs text-gray-subtitle mb-1'>이메일</div>
                    <Controller
                        name='userId'
                        control={control}
                        rules={{ required: '이메일을 입력해 주세요.' }}
                        render={({ field }) => (
                            <Input {...field}
                                   type='text'
                                   variant = 'filled'
                                   className={`w-[360px] h-11 ${errors.userId ? 'mb-1' : 'mb-3'}`}
                                   placeholder='이메일을 입력해 주세요.'
                                   onKeyDown={onChangeInputText}
                            />
                        )}
                    />
                    <p className={`text-red text-sm ml-3 mb-2 ${errors.userId ? 'visible' : 'invisible'}`}>{errors?.userId?.message}</p>
                    <div className='text-xs text-gray-subtitle mb-1'>비밀번호</div>
                    <Controller
                        name='userPw'
                        control={control}
                        rules={{ required: '비밀번호를 입력해 주세요.' }}
                        render={({ field }) => (
                            <Input {...field}
                                   type='password'
                                   variant = 'filled'
                                   className='w-[360px] h-11 mb-1'
                                   placeholder='비밀번호를 입력해 주세요.'
                                   onKeyDown={onChangeInputText}
                            />
                        )}
                    />
                    <p className={`text-red text-sm ml-3 mb-2 ${errors.userPw ? 'visible' : 'invisible'}`}>{errors?.userPw?.message}</p>
                    <div className='flex items-center my-1'>
                        <input type='checkbox' className='ml-1' checked={saveCheck} onChange={() => setSaveCheck(!saveCheck)} />
                        <div className='text-sm ml-2'>로그인 유지</div>
                    </div>
                    {inspection && <div className='text-sm text-red text-center'>서버 긴급 점검 중 입니다.</div>}
                    </div>
                </div>
                <div className='flex justify-center mt-3'>
                    <Input className='bg-prime-blue text-white w-[360px] h-12 mt-1' type='submit' value='로그인' />
                </div>
                <Typography className='text-xs text-light-gray mt-3'>
                    <div className='mb-2'>아직 MQV 스마트오피스 회원이 아닌가요? <span className='font-bold cursor-pointer text-black' onClick={handleGoToJoin}>회원가입</span></div>
                    <div className='mb-2'>아이디가 기억나지 않나요? <span className='font-bold cursor-pointer text-black' onClick={handleGoToJoin}>아이디 찾기</span></div>
                    <div>비밀번호가 기억나지 않나요? <span className='font-bold cursor-pointer text-black' onClick={handleGoToJoin}>비밀번호 찾기</span></div>
                </Typography>
            </div>
        </form>
        <CommonModal
            isVisible={error && !isChangeInputText}
            style={{top: 300}}
            content={
                <p>이메일 또는 비밀번호를 잘못 입력했습니다. <br/>
                    입력하신 내용을 다시 확인해 주세요.
                </p>
            }
            onOk={handleOk}
            okText={'확인'}
            width={360}
            cancelButtonProps={{ style: {display : 'none'} }}
        />
        </div>
    )
}

export const getServerSideProps = withSession(async function ({ req }) {
    req.session.destroy()
    return {
        props: {},
    }
})

export default LoginPage
